<template>
  <div style="position: relative">
    <div
      class="j-shopbag-title-height"
      style="height: 116px;display: none"
    ></div>
    <div class="j-shopbag-title cart-list__header">
      <div class="cart-list__header-title">
        <div class="new-cart__title-left">
          <h4
            :aria-label="`${language.SHEIN_KEY_PC_22381}(${allQuantity})`"
            tabindex="0"
          >
            <s-checkbox
              label="all"
              :model-value="isCheckedAll"
              :disabled="isDisabledCheckedAll || doFilter"
              :outside-check="true"
              :gap="'4px'"
              @change="handleCheckedAll"
            >
              <span class="new-cart__title-text">{{ `${language.SHEIN_KEY_PC_22381} (${allQuantity})` }}</span>
            </s-checkbox>
          </h4>
          <ClientOnly>
            <RealTimeLabels
              v-if="usefulRealTimeLabels.length && !locals.IS_RW"
              :labels="usefulRealTimeLabels"
              :language="language"
            />
          </ClientOnly>
        </div>
        <div
          v-show="cartList.length > 1"
          v-enterkey
          class="cart-list__header-batch"
          tabindex="0"
          role="button"
          @click="handleBatchItems"
        >
          <sui_icon_select_16px_1 size="16px" />
          <span>{{ language.SHEIN_KEY_PC_15659 }}</span>
        </div>
      </div>
    </div>
    <CartListFilter
      v-if="cartsId.length > 4 && goodsFilterLabels.length > 0"
      :goods-filter-labels="goodsFilterLabels"
      :model-value="filterType"
      @update:model-value="handleFilterClick"
      @update-goods-tip-show="updateGoodsTipShow"
    />
    <!-- 品类津提示信息 -->
    <CartFilterVoucherTips
      v-if="filterType == 'category_voucher' && isShowFilterTips"
      :tip-content="language.SHEIN_KEY_PC_30874"
      @close="filterTipsClose"
    />
    <!-- 促销活动、商品属性类提示信息 -->
    <CartFilterGoodsTips v-if="showGoodsTip" />
    <div
      class="cart-list__content"
      :class="[
        isPlatform ? 'cart-list__platform': !showOBMStore && showThreeStore ? 'show-three-title' : 'cart-list__platform-not',
        {
          'show-obm-title': showOBMStore,
          'adjust-margin': goodsFilterLabels.length > 0 && !targetCouponInfo?.id
        }
      ]"
    >
      <cart-list-empty
        v-show="!cartSumQuantity"
        :is-real-show="isRealShow"
      />
      <template
        v-for="mallCartItem in mallCartList"
        :key="mallCartItem.mall_code"
      >
        <template v-if="isPlatform">
          <platform-warehouse-header
            v-show="!doFilter + showFilterHeader(mallCartItem, filterType, 'mall', targetCouponInfo, goodsFilterLabels)"
            :key="mallCartItem.mall_code"
            :mall-code="mallCartItem.mall_code"
            :show-first-store-title="showOBMStore || showThreeStore"
          >
            <s-checkbox
              v-show="!doFilter"
              :label="mallCartItem.mall_code"
              :disabled="isDisabledWarehouse(mallCartItem.mall_code)"
              :model-value="isCheckedWarehouse(mallCartItem.mall_code)"
              :gap="'4px'"
              :outside-check="true"
              @change="handleWarehouseCheckedItem"
            />
          </platform-warehouse-header>
        </template>
        <template v-if="!isPlatform">
          <!-- 全场满赠(付费会员)(有门槛) -->
          <full-gift-header
            v-if="sheinClubThresholdFullGiftPromotion(mallCartItem.mall_code)"
            :key="'sheinClubThresholdFullGiftPromotion'+ mallCartItem.mall_code"
            :integrity-promotion-info="sheinClubThresholdFullGiftPromotion(mallCartItem.mall_code)"
          />
          <!-- 全场满赠(付费会员)(无门槛) -->
          <full-gift-header
            v-if="sheinClubFullGiftPromotion(mallCartItem.mall_code)"
            :key="'sheinClubFullGiftPromotion-'+ mallCartItem.mall_code"
            :integrity-promotion-info="sheinClubFullGiftPromotion(mallCartItem.mall_code)"
          />
          <full-gift-header
            v-if="fullGiftPromotion(mallCartItem.mall_code)"
            :key="'fullGiftPromotion-'+ mallCartItem.mall_code"
            :integrity-promotion-info="fullGiftPromotion(mallCartItem.mall_code)"
          />
          <full-add-on-header
            v-if="fullAddOnPromotion(mallCartItem.mall_code)"
            :key="'fullAddOnPromotion-'+ mallCartItem.mall_code"
            :integrity-promotion-info="fullAddOnPromotion(mallCartItem.mall_code)"
          />
        </template>
        <template v-for="listItem in mallCartItem.mall_list">
          <!-- 泰国站等站点需要根据abt判断是否展示店铺信息, 因此不能仅依据数据来判断 -->
          <template v-if="(showOBMStore && listItem.store_code ) || (!showOBMStore && showThreeStore && listItem.store_code)">
            <platform-store-header
              v-show="!doFilter + showFilterHeader((listItem.store_code ? listItem.store_list : [listItem]), filterType, 'store', targetCouponInfo, goodsFilterLabels)"
              :key="mallCartItem.mall_code +'-'+listItem.store_code"
              :store-code="listItem.storeCode"
              :store-logo="listItem.store_logo"
              :store-title="listItem.store_title"
              :mall-code="mallCartItem.mall_code"
              :store-type="listItem.store_type"
              :preferred-seller-store="listItem.preferred_seller_store"
              :store-list-origin="listItem.store_list_origin"
            >
              <s-checkbox
                v-show="!doFilter"
                :label="mallCartItem.mall_code +','+listItem.store_code"
                :model-value="isChecked(listItem.store_list_origin)"
                :disabled="isDisabled(listItem.store_list_origin)"
                :gap="'4px'"
                :outside-check="true"
                @change="handleStoreCheckedItem"
              />
            </platform-store-header>
            <template v-if="listItem.innerUnGroupPromotions && listItem.innerUnGroupPromotions.length > 0">
              <promotion-group-header
                v-for="storePromoHeader in listItem.innerUnGroupPromotions"
                v-show="!doFilter + showFilterHeader((listItem.store_code ? listItem.store_list : [listItem]), filterType, 'store', targetCouponInfo, goodsFilterLabels)"
                :key="'store' + listItem.storeCode +storePromoHeader.promotion_id"
                class="all-store-header"
                :language="language"
                :abt-params="ABTParams"
                :integrity-promotion-info="storePromoHeader"
                :cart-promotion-map="cartPromotionMap"
                :css-right="locals.GB_cssRight"
                @handle-add-on="handleAddOn"
              />
            </template>
          </template>
          <template 
            v-for="(promotionGroup, index) in (listItem.store_code ? listItem.store_list : [listItem])" 
            :key="mallCartItem.mall_code +'-'+(promotionGroup.promotion_id || promotionGroup.id) +'-'+ listItem.store_code"
          >
            <promotion-group-header
              v-if="promotionGroup.id != 'common' && ![4,22].includes(+promotionGroup.type_id)"
              v-show="!doFilter + showFilterHeader(promotionGroup.list, filterType, targetCouponInfo, goodsFilterLabels)"
              :language="language"
              :abt-params="ABTParams"
              :integrity-promotion-info="promotionGroup"
              :cart-promotion-map="cartPromotionMap"
              :css-right="locals.GB_cssRight"
              @handle-add-on="handleAddOn"
            >
              <s-checkbox
                v-show="!doFilter"
                :label="promotionGroup.promotion_id"
                :model-value="isChecked(promotionGroup.list)"
                :disabled="isDisabled(promotionGroup.list)"
                :gap="'4px'"
                :outside-check="true"
                @change="handleGroupCheckedItem"
              />
            </promotion-group-header>
            <div
              v-else-if="promotionGroup.id == 'common' && listItem.store_list[index - 1] && listItem.store_list[index - 1].group_type"
              class="common-group-header"
            ></div>
            <cart-list-item
              v-for="(item, index1) in promotionGroup.list.filter(i => cartsId.indexOf(i.id) < onlySsrRenderNum)"
              :key="index1+item.id"
              :item="item"
              :index="cartsId.indexOf(item.id)"
              :item-promotion-group-info="promotionGroup.group_type=='store' ? {} : promotionGroup"
              :is-inline-store="promotionGroup.subject == 1 && item.promotionGroupId && !(!showOBMStore && !showThreeStore)"
              @on-uncheck="handleCheckedItem"
            >
              <div
                class="checkbox-item"
                :style="{ opacity: (item.isGiftItem) ? 0 : 1 }"
              >
                <SPopover
                  :content="getDisabledReason(item)"
                  :empty-un-show="true"
                  fix-disappear-position="true"
                  theme="dark"
                  placemen="right"
                  trigger="hover"
                  @opened="exposeCheckItem(item)"
                >
                  <template #reference>
                    <div class="newcomer-guide__checkbox">
                      <s-checkbox
                        :label="item.id"
                        :disabled="item.isDisabled"
                        :model-value="Boolean(item.is_checked)"
                        :gap="'0px'"
                        :outside-check="true"
                        @change="handleCheckedItem"
                      />
                    </div>
                  </template>
                </SPopover>
              </div>
            </cart-list-item>
            <ClientOnly>
              <cart-list-item
                v-for="(item, index1) in promotionGroup.list.filter(i => cartsId.indexOf(i.id) >= onlySsrRenderNum && ( renderCount ? renderCount > cartsId.indexOf(i.id) : cartsId.indexOf(i.id) >= renderCount))"
                :key="index1+item.id"
                :item="item"
                :index="cartsId.indexOf(item.id)"
                :item-promotion-group-info="promotionGroup.group_type=='store' ? {} : promotionGroup"
                :is-inline-store="promotionGroup.subject == 1 && item.promotionGroupId && !(!showOBMStore && !showThreeStore)"
              >
                <div
                  class="checkbox-item"
                  :style="{ opacity: (item.isGiftItem) ? 0 : 1 }"
                >
                  <SPopover
                    :content="getDisabledReason(item)"
                    :empty-un-show="true"
                    :fix-disappear-position="true"
                    theme="dark"
                    placemen="right"
                    trigger="hover"
                    @opened="exposeCheckItem(item)"
                  >
                    <template #reference>
                      <div class="newcomer-guide__checkbox">
                        <s-checkbox
                          :label="item.id"
                          :disabled="item.isDisabled"
                          :model-value="Boolean(item.is_checked)"
                          :gap="'0px'"
                          :outside-check="true"
                          @change="handleCheckedItem"
                        />
                      </div>
                    </template>
                  </SPopover>
                </div>
              </cart-list-item>
            </ClientOnly>
            <!-- 按店铺或促销组维度商品列表后方添加padding, 8px, 无法通过仅css实现 -->
            <div
              v-if="promotionGroup.list.length > 0"
              v-show="!doFilter + showFilterHeader((showOBMStore ? [listItem] : listItem.store_list), filterType, 'store', targetCouponInfo, goodsFilterLabels)"
              class="place-holder"
              style="padding-bottom: 8px;background: #fff;"
            ></div>
          </template>
        </template>
        <NegativeInfo
          v-expose="{ id: '1-7-3-19', data: { mall_code: mallCartItem.mall_code } }"
          :mall-code="mallCartItem.mall_code"
        />
      </template>
      <template v-if="soldoutItems.length">
        <SoldOutHeader v-show="!doFilter" />
        <CartSoldOutItems
          v-show="!doFilter"
          :soldout-items="soldoutItems"
          :carts-id="cartsId"
        />
        <div
          v-show="!doFilter"
          class="place-holder"
          style="padding-bottom: 8px;background: #fff;"
        ></div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import cartListEmpty from './cartListEmpty.vue'
import promotionGroupHeader from './promotionGroupHeader.vue'
import cartListItem from './cartListItem.vue'
import fullAddOnHeader from './fullAddOnHeader.vue'
import fullGiftHeader from './fullGiftHeader.vue'
import platformWarehouseHeader from '../../components/platform/platformWarehouseHeader.vue'
import platformStoreHeader from '../../components/platform/platformStoreHeader.vue'
import CartSoldOutItems from './newList/CartSoldOutItems.vue'
import SoldOutHeader from './newList/SoldOutHeader.vue'
import NegativeInfo from './newList/NegativeInfo.vue'
import CartListFilter from './CartListFilter.vue'
import { showFilterHeader } from '../../utils/cartFilterLabels.js'
import { judgeActivityIsExist } from '../../utils/index'
import { daEventCenter } from '../../../../services/eventCenter'
import { defineAsyncComponent } from 'vue'
import { transfromAndCutImg } from 'public/src/services/resource/index'
import { sui_icon_select_16px_1 } from '@shein-aidc/icon-vue3'
import { getCouponListForCart } from 'public/src/pages/cart_new/utils/fetcher.js'
import { web_modify_check_click, web_modify_check_response } from 'public/src/pages/cart_new/utils/metricTagsConfig.js'
import { exposeScenesAbtAggregator } from 'public/src/pages/cart_new/utils/EventAggregator.js'

export default {
  name: 'CartListVue',
  components: {
    cartListEmpty,
    promotionGroupHeader,
    platformStoreHeader,
    platformWarehouseHeader,
    cartListItem,
    fullAddOnHeader,
    fullGiftHeader,
    CartSoldOutItems,
    SoldOutHeader,
    NegativeInfo,
    CartListFilter,
    CartFilterVoucherTips: defineAsyncComponent(() => import('@/public/src/pages/cart_new/components/list/CartFilterVoucherTips.vue')),
    CartFilterGoodsTips: defineAsyncComponent(() => import('@/public/src/pages/cart_new/components/list/CartFilterGoodsTips.vue')),
    RealTimeLabels: defineAsyncComponent(() => import('public/src/pages/cart_new/components/behaviorLabel/realTimeLables.vue')),
    sui_icon_select_16px_1
  },
  props: {
    isRealShow: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      onlySsrRenderNum: 6,
      renderCount: 6,
      isShowFilterTips: false,
      showGoodsTip: false, // 是否展示筛选承接信息
    }
  },
  emits: ['renderEnd'],
  computed: {
    ...mapState([
      'locals',
      'language',
      'cartInfo',
      'cartSumQuantity', 
      'isPlatform', 
      'soldoutItems', 
      'cartsId', 
      'cartList', 
      'ABTParams', 
      'integrityPromotionInfo', 
      'soldoutIds', 
      'mallCartList', 
      'goodsFilterLabels', 
      'filterType', 
      'blackFridayImgGoods',
      'realTimeBehaviorLabels',
      'couponInfo',
      'targetCouponInfo',
      'couponFilterLabelData',
      // 'stopAllSwiper'
    ]),
    ...mapGetters(['cartPromotionMap', 'showSheinShop', 'showOBMStore', 'showThreeStore', 'isSheinClubMember']),
    normalCartList(){
      return this.cartList.filter(item => !this.soldoutItems.includes(item.id))
    },
    isCheckedAll(){
      let cartsAll = this.normalCartList.filter(item => !this.soldoutIds.includes(item.id))
      if(cartsAll.length) {
        return cartsAll.filter(item => item.is_checked == 0).length ? false : true
      }
      return false
    },
    isDisabledCheckedAll(){
      let cartsAll = this.cartList.filter(item => !this.soldoutIds.includes(item.id))
      if(!cartsAll.length) {
        return true
      }
      return false
    },
    checkedQuantity(){
      let cartCheckedNum = 0
      this.cartList.filter(item => item.is_checked == 1).forEach(cartItem => {
        cartCheckedNum += cartItem.quantity
      })
      return cartCheckedNum
    },
    allQuantity() {
      return this.normalCartList.length
    },
    // doFilter为false时, 所有item都显示, 当其为true时, 再根据filterType筛选合适的item展示
    doFilter() {
      return this.filterType != 'none'
    },
    behaviorShow() {
      let abtStrValue = 'fivestar,adds,sales2,buyagain,lowestprice'
      if (!abtStrValue || abtStrValue == 'Hide') return false
      return true
    },
    usefulRealTimeLabels() {
      const normalGoodsIdArr = this.normalCartList?.map((v) => v.product?.goods_id)
      return this.realTimeBehaviorLabels?.filter((v) => v.showLabelTag && normalGoodsIdArr.includes(v.goodsId))
    },
  },
  watch: {
    blackFridayImgGoods(goods) {
      const hasLabel = Object.keys(goods).some(key => !!goods[key]?.showBehaviorLabel?.length)
      exposeScenesAbtAggregator.addEvent({
        daId: '1-7-3-22',
        extraData: {
          scenes: 'userbehavior_tips',
          type: hasLabel ? this.behaviorShow ? 3 : 4 : this.behaviorShow ? 1 : 2
        }
      })
    },
  },
  mounted() {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(async () => {
        this.setListItem()
      })
    } else {
      this.setListItem()
    }
    
    // 促销活动结果曝光
    daEventCenter.triggerNotice({
      daId: '1-7-1-12',
      extraData: {
        activity_method: judgeActivityIsExist(this.cartList, [3, 10])
      }
    })
    if (typeof window !== 'undefined') {
      let voucherFilterTipShowed = Boolean(localStorage.getItem('voucherFilterTipShowed'))
      if (voucherFilterTipShowed) { // show only once
        this.isShowFilterTips = false
      } else { // show only once
        this.isShowFilterTips = true
      }
    }
  },
  provide() {
    return {
      cutImg: this.cutImg
    }
  },
  methods: {
    ...mapMutations(['updateField', 'updateAddForCoupon']),
    ...mapActions(['fetchModifyCartCheckStatus', 'handleFlashSaleOverScene', 'fetchCarts', 'handleCartFilterLabels']),
    // 打开批处理
    handleBatchItems() {
      this.updateField({ key: 'batchActive', value: true })
      this.updateField({ key: 'filterType', value: 'none' })
    },
    exposeCheckItem(item) {
      daEventCenter.triggerNotice({
        daId: '1-7-3-67',
        extraData: {
          type: item.isMallSwitchGoods ? 1 : item.isSaleAttrSwitchGoods ? 2 : '',
        }
      })
    },
    getDisabledReason(item) {
      if (item.isMallSwitchGoods) {
        return this.language.SHEIN_KEY_PC_32190
      } else if (item.isSaleAttrSwitchGoods) {
        return this.language.SHEIN_KEY_PC_32191
      }
      return ''
    },
    isDisabledWarehouse(mallCode) {
      return !this.cartList.filter(item => item.mall_code == mallCode && !this.soldoutIds.includes(item.id)).length
    },
    isDisabled(goodsList = []) {
      return !goodsList.filter(item => !this.soldoutIds.includes(item.id)).length
    },
    isCheckedWarehouse(mallCode){
      let mallItemCartList = this.cartList.filter(item => item.mall_code == mallCode && !this.soldoutIds.includes(item.id))
      return this.isChecked(mallItemCartList)
    },
    isChecked (goodsList = []) {
      if (this.isDisabled(goodsList)) return false
      return goodsList.filter(item => item.is_checked == 0 && !this.soldoutIds.includes(item.id)).length ? false : true
    },
    handleCheckedAll ( { checked = false }){
      web_modify_check_click(checked ? 1 : 0, 'all')
      let reqParam = {
        operation_type: checked ? 3 : 4,
      }
      daEventCenter.triggerNotice({
        daId: '1-7-3-11',
        extraData: {
          is_select: checked ? 0 : 1,
        }
      })
      this.fetchModifyCartCheckStatus(reqParam).then((res) => {
        web_modify_check_response(checked ? 1 : 0, 'all', res?.code == 0 ? 1 : 0)
      })
    },
    handleCheckedItem ( { label = '', checked = false }){
      web_modify_check_click(checked ? 1 : 0, 'item')
      let targetItem = this.cartList.find(item => item.id == label)
      if((parseInt(targetItem.quantity) > parseInt(targetItem.realTimeInventory))){
        this.$message({
          message: this.language.SHEIN_KEY_PC_21623,
          type: 'error'
        })
        return
      }
      let reqParam = {
        operation_type: checked ? 1 : 2,
        cart_id_list: [],
        editCartId: label
      }
      reqParam.cart_id_list.push(label)
      if(checked){
        reqParam.cart_id_list = reqParam.cart_id_list.concat(targetItem.appendIds || [])
      }
      daEventCenter.triggerNotice({
        daId: '1-7-3-10',
        extraData: {
          select_type: 1,
          is_select: checked ? 0 : 1,
          position: 'page',
          // 勾选商品时在哪个筛选标签下。注意券需要上报couponType
          label_id: !isNaN(this.filterType)
            ? this.goodsFilterLabels.find(i => this.filterType == i.id)?.couponType
            : this.filterType
        }
      })
      this.fetchModifyCartCheckStatus(reqParam).then((res) => {
        web_modify_check_response(checked ? 1 : 0, 'item', res?.code == 0 ? 1 : 0)
      })
    },
    handleGroupCheckedItem ( { label = '', checked = false }){
      web_modify_check_click(checked ? 1 : 0, 'section')
      let reqParam = {
        operation_type: checked ? 1 : 2,
        cart_id_list: []
      }
      let groupCartList = this.integrityPromotionInfo[label]?.list || []
      reqParam.cart_id_list = groupCartList.map(item => item.id)
      if(checked){
        let giftIds = this.findGiftOrAddonCartItemId(groupCartList)
        reqParam.cart_id_list = reqParam.cart_id_list.concat(giftIds)
      }
      daEventCenter.triggerNotice({
        daId: '1-7-3-10',
        extraData: {
          select_type: 2,
          is_select: checked ? 0 : 1,
          position: 'page',
        }
      })
      this.fetchModifyCartCheckStatus(reqParam).then((res) => {
        web_modify_check_response(checked ? 1 : 0, 'section', res?.code == 0 ? 1 : 0)
      })
    },
    handleStoreCheckedItem ( { label = '', checked = false }){
      web_modify_check_click(checked ? 1 : 0, 'section')
      let reqParam = {
        operation_type: checked ? 1 : 2,
        cart_id_list: []
      }
      let [mall_code = '', store_code = ''] = label.split(',')
      let mallItem = this.mallCartList.find(mall => mall.mall_code == mall_code)
      let storeCartList = mallItem && mallItem.mall_list.find(item => item.store_code == store_code)?.store_list_origin || []
      reqParam.cart_id_list = storeCartList.map(item => item.id)
      if(checked){
        let giftIds = this.findGiftOrAddonCartItemId(storeCartList)
        reqParam.cart_id_list = reqParam.cart_id_list.concat(giftIds)
      }
      daEventCenter.triggerNotice({
        daId: '1-7-3-10',
        extraData: {
          select_type: 2,
          is_select: checked ? 0 : 1,
          position: 'page',
        }
      })
      this.fetchModifyCartCheckStatus(reqParam).then((res) => {
        web_modify_check_response(checked ? 1 : 0, 'section', res?.code == 0 ? 1 : 0)
      })
    },
    handleWarehouseCheckedItem ( { label = '', checked = false }){
      web_modify_check_click(checked ? 1 : 0, 'section')
      let reqParam = {
        operation_type: checked ? 1 : 2,
        cart_id_list: []
      }
      let cartList = this.cartList.filter(item => item.mall_code == label && !this.soldoutIds.includes(item.id))
      reqParam.cart_id_list = cartList.map(item => item.id)
      if(checked){
        let giftIds = this.findGiftOrAddonCartItemId(cartList)
        reqParam.cart_id_list = reqParam.cart_id_list.concat(giftIds)
      }
      daEventCenter.triggerNotice({
        daId: '1-7-3-10',
        extraData: {
          select_type: 2,
          is_select: checked ? 0 : 1,
          position: 'page',
        }
      })
      this.fetchModifyCartCheckStatus(reqParam).then((res) => {
        web_modify_check_response(checked ? 1 : 0, 'section', res?.code == 0 ? 1 : 0)
      })
    },
    soldOutItemTip(){
      this.$message({
        message: this.language.SHEIN_KEY_PC_21702,
        type: 'error'
      })
    },
    findGiftOrAddonCartItemId(groupCartList = []){
      let giftIds = []
      groupCartList.forEach(item => {
        giftIds = giftIds.concat(item.appendIds || [])
      })
      giftIds = [...new Set(giftIds)]
      return giftIds
    },
    // 全场满赠（非付费会员）
    fullGiftPromotion(mallCode) {
      return Object.values(this.integrityPromotionInfo).find(item => +item.type_id === 4 && ![8, 14].includes(item.promotion_logo_type) && item.mall_code == mallCode)
    },
    // 全场满赠(付费会员)(无门槛)
    sheinClubFullGiftPromotion(mallCode) {
      return this.isSheinClubMember && Object.values(this.integrityPromotionInfo).find(item => +item.type_id === 4 && item.promotion_logo_type === 8 && item.mall_code == mallCode)
    },
    // 全场满赠(付费会员)(有门槛)
    sheinClubThresholdFullGiftPromotion(mallCode) {
      return this.isSheinClubMember && Object.values(this.integrityPromotionInfo).find(item => +item.type_id === 4 && item.promotion_logo_type === 14 && item.mall_code == mallCode)
    },
    fullAddOnPromotion(mallCode){
      return Object.values(this.integrityPromotionInfo).find(item => [22].includes(+item.type_id) && item.mall_code == mallCode)
    },
    async handleFilterClick(res) {
      const isCoupon = !isNaN(res) // 选中的筛选标签是否为券
      this.updateField({ key: 'bigLoading', value: true })
      let targetCoupon = {}
      try {
        if (isCoupon) {
          // 如果选中的是券，同时调券助手接口获取最新的券数据并保存当前选中的券数据
          const promiseRes = await Promise.all([this.fetchCarts(), getCouponListForCart()])
          const couponInfoRes = promiseRes[1]
          const couponId = res
          if (couponInfoRes.code == 0) {
            const { disabledCouponList, usableCouponList } = couponInfoRes?.info
            const coupon = [...disabledCouponList, ...usableCouponList]
            targetCoupon = coupon?.find(i => i.id == couponId)
            if (targetCoupon?.id) {
              targetCoupon['couponType'] = targetCoupon.coupon_type_id == '1' 
                ? `coupon_money_${targetCoupon.coupon}`
                : `coupon_off_${targetCoupon.coupon}`   // 用于埋点
            } else {
              // 如果targetCoupon为空，说明这张券已经失效，需要重新更新登陆的券标签
              // console.log('--这张券已经失效', couponId)
              this.$message({
                message: this.language.SHEIN_KEY_PC_25421,
                type: 'error'
              })
              this.updateField({ key: 'couponFilterLabelData', value: res?.info || {} })
              this.handleCartFilterLabels()
              this.updateField({ key: 'targetCouponInfo', value: {} })
              this.updateField({ key: 'filterType', value: 'none' })
              return
            }
          }
        } else {
          await this.fetchCarts()
        }
      } catch (error) {
        console.error('fetchCarts', error)
      }
      // 筛选状态更新后页面回到顶部
      if (window.scrollY) {
        window.scrollTo({ top: 0 })
      }
      let curSelectedLabel, targetLabel
      if (this.filterType != 'none') {
        curSelectedLabel = this.goodsFilterLabels.find(i => i.type == 'coupon'
          ? i.id == this.filterType 
          : i.type == this.filterType
        )
      }
      if (res == 'none') {
        if(this.filterType != 'none' && !curSelectedLabel) {
          this.$message({
            message: this.language.SHEIN_KEY_PC_25421,
            type: 'error'
          })
        }
        this.updateField({ key: 'filterType', value: res })
        this.updateField({ key: 'targetCouponInfo', value: targetCoupon })
      } else {
        targetLabel = this.goodsFilterLabels.find(i =>
          i.type == 'coupon' ? i.id == res : i.type == res
        )
        if(!targetLabel) { // 点击目标标签, 更新数据后不存在
          this.$message({
            message: this.language.SHEIN_KEY_PC_25421,
            type: 'error'
          })
          // 当前已选中标签, 更新数据后不存在, 则取消选中
          if(this.filterType != 'none' && !curSelectedLabel) {
            this.updateField({ key: 'filterType', value: 'none' })
            this.updateField({ key: 'targetCouponInfo', value: {} })
          } // 当前已有标签, 且更新数据后当前标签存在, 目标标签不存在,则不做任何操作
          // 当前无标签, 且更新数据后目标标签不存在, 无标签状态无需更改, 不做任何操作
        } else { // 点击的目标标签存在
          if(this.filterType != 'none' && !curSelectedLabel) {
            this.$message({
              message: this.language.SHEIN_KEY_PC_25421,
              type: 'error'
            })
          }
          this.updateField({ key: 'filterType', value: res })
          this.updateField({ key: 'targetCouponInfo', value: targetCoupon })
        }
      }
      this.updateField({ key: 'bigLoading', value: false })
      // 保存筛选状态, 用于下单页 click_place_order 埋点
      sessionStorage.setItem('filterType', this.filterType)
    },
    showFilterHeader,
    handleAddOn(promotion) {
      this.updateAddForCoupon({ 
        show: true, 
        type: 'AddOnV2', 
        promotion,
      })
    },
    cutImg (imgUrl, designWidth, exp) {
      const { deviceData = '', isSupportWeb = '', isSupprotCut = false } = this.locals.RESOURCE_SDK || {}

      const cutData = {
        deviceData,
        isSupportWebp: Boolean(isSupportWeb),
        isSupprotCut,
        imgUrl,
        designWidth: Number(designWidth),
        exp,
      }

      return transfromAndCutImg(cutData)
    },
    // 分块渲染前6个商品
    setListItem() {
      if (this.renderCount >= this.normalCartList.length) {
        this.$emit('renderEnd')
        this.renderCount = 0
        return
      }
      requestAnimationFrame(() => {
        this.renderCount += 6
        this.setListItem()
      })
    },
    filterTipsClose() {
      this.isShowFilterTips = false
      localStorage.setItem('voucherFilterTipShowed', true) // 关闭后不再展示
    },
    updateGoodsTipShow(val) {
      this.showGoodsTip = val
    }
  }
}
</script>

<style lang="less">
.cart-table{
  display:table;
  width: 100%;
  .cart-row{
    display:table-row;
    list-style-type:none;
    .cart-row-cell{
      display:table-cell;
      vertical-align: top;
      text-align: left;
    }
    .cart-img{
      width: 50%;
      position: relative;
    }
    .cart-price{
      width: 20%;
      padding-bottom: 20px;
    }
    .cart-qty{
      width: 13%;
    }
    .cart-total{
      width: 17%;
      text-align: right;
    }
    .cart-item-cell{
      padding-left: 110px;
      position: relative;
      //bottom: 7px;
    }
  }
}
.cart-table-h{
  font-weight: bold;
  font-size: 14px;
  color: #222222;
  padding: 14px 0;
}
.cart-list__header{
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 16px 12px;
  background: #fff;
  .cart-list__header-title{
    padding-bottom: 4px;
  }
  .new-cart__title-left {
    display: flex;
    align-items: center;
  }
}
.cart-list__header-title {
  .flexbox();
  justify-content: space-between;
  align-items: center;
  >h4 {
    font-size: 20px /* rw: 18px */;
    color: #222222;
    display: inline-block;
    text-transform: capitalize;
    font-family: Arial-Black, Arial /* rw: Adieu */;
  }
}
.cart-list__header-batch {
  cursor: pointer;
  color: #222;
  font-size: 13px;
  >span {
    vertical-align: middle;
    font-weight: bold;
  }
}
.cart-list__content{
  margin-top: 10px;
  .cart-table + .warehouse-container, .cart-table + .sold-out-group{
    margin-top: 20px;
  }
  .empty-cart-container{
    padding-bottom: 24px;
  }
  // 档筛选栏存在且选中不为券标签时，需要隐藏10px的间距
  // &.adjust-margin {
  //    margin-top: -5px;
  // }
}
.cart-list__platform,.show-three-title{
  .common-group-header{
    padding: 0 16px;
    height: 1px;
    background: #fff;
  }
  .cart-table + .cart_item-header, .cart_item-header + .cart_item-header, .cart-table + .common-group-header, .cart_item-header + .common-group-header{
    position: relative;
    &:before {
      border-bottom: 1px dashed #e5e5e5;
      content: "";
      width: calc(100% - 48px);
      display: block;
      top: 0;
      position: absolute;
    }
  }
  .mall-section-item + .mall-section-item, .cart-table + .sold-out-group{
    &:before {
      border-bottom: 0;
    }
  }
}
.cart-list__platform-not{
  .cart-table + .cart_item-header, .cart_item-header + .cart_item-header, .cart-table + .common-group-header, .cart_item-header + .common-group-header{
    margin-top: 10px;
  }
}
.show-obm-title{
  .cart_item-header,.store-header-container{
    margin-top: 5px;
    &:first-child{
      margin-top: 10px;
    }
  }
  /* stylelint-disable-next-line selector-max-specificity */
  .cart-table + .cart_item-header:not(.sold-out-group), .cart_item-header + .cart_item-header{
    margin-top: 5px;
    /* stylelint-disable-next-line selector-max-specificity */
    &:before {
      border-bottom: 0;
    }
  }
}
.show-three-title{
  .store-header-container{
    margin-top: 10px;
    &:first-child{
      margin-top: 0;
    }
  }
}
.cart_item-header{
  padding: 20px 24px 0;
  background: #FFFFFF;
  &.not-promoGoods{
    padding-bottom: 20px;
  }
  .cart-item__header-section{
    .flexbox();
    .space-between();
    .align-center();
    background: @sui_color_promo_bg;
    padding: 16px;
    >div{
      .padding-r(32px);
      flex: 1;
    }
    .cart_item-title{
      font-size: 16px;
      color: #000;
      font-weight: 700;
      margin-bottom: 4px;
      /* stylelint-disable-next-line selector-max-specificity */
      .c-count__down-time{
        font-size: 12px;
        display: inline-block;
        margin-left: 10px;
        font-weight: normal;
        color: @sui_color_highlight;
        margin-top: 0;
      }
      /* stylelint-disable-next-line selector-max-specificity */
      &.full-title{
        font-size: 14px;
      }
    }
    .cart_item-desc{
      color: #000000;
      font-size: 13px;
      line-height: 15px;
      em{
        color: @sui_color_welfare_dark;
      }
    }
  }
}
.checkbox-item{
  // display: inline-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 16px;
  .newcomer-guide__checkbox {
    .sui-checkbox__label-text {
      padding-left: 0;
    }
  }
}
.cart-list__header-sticky{
  position: sticky;
  top: 0;
  z-index: @zindex-out;
}
.top-container-fixed {
  position: fixed;
  top: 0;
  z-index: 10;
}

.cart-list__content {
  /* stylelint-disable-next-line selector-max-specificity */
  .cart-table+.cart_item-header:not(.sold-out-group) {
    margin-top: 1px;
  }
  .cart_item-header.promo-group {
    background: #FFF6F3;
    padding: 12px 16px;
    margin-top: 1px;
    section {
      padding: 2px 0;
    }
  }
  .cart_item-header.not-promoGoods {
    padding: 0 16px;
    margin-top: 1px;
  }
  .cart_item-header:first-child {
    margin-top: 5px;
  }
  .common-group-header {
    padding: 0 16px;
    height: 1px;
    background: #fff;
  }
  .place-holder + .common-group-header {
    position: relative;
    &:before {
      border-bottom: 1px dashed #e5e5e5;
      content: "";
      width: calc(100% - 32px);
      display: block;
      top: 0;
      position: absolute;
    }
  }
  // 单mall间距与负向信息样式
  &:not(.cart-list__platform) {
    .cart-negative-info {
      background: unset;
    }
    .cart_item-header {
      margin-top: 5px;
    }
  }
}
.new-cart__title-text {
  font-size: 20px;
  font-weight: 900;
  font-family: 'Arial Black';
  color: @sui_color_gray_dark1;
  margin-right: 12px;
}
.cart-list__header-batch {
  >*:not(:last-child) {
    margin-right: 4px;
  }
}
</style>

<style lang="less" scoped>
.cart-list__content .all-store-header {
  margin-top: 0px;
}
</style>
