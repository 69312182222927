import { daEventCenter } from 'public/src/services/eventCenter/index'

/**
 * 事件聚合发送基类
 */
export default class EventAggregator {
  constructor(options = {}) {
    const {
      interval = gbCommonInfo?.EVENT_AGGREGATOR_INTERVAL || 500, // 发送间隔, fallback to default value
      debug = true, // 调试模式
    } = options

    this.interval = interval
    this.debug = debug
    this.eventQueue = new Map()
    this.timer = null

    if (new.target === EventAggregator) {
      throw new Error('EventAggregator cannot be instantiated directly')
    }
  }

  /**
   * 启动定时器
   */
  startTimer() {
    if (!this.timer) {
      this.timer = setInterval(() => this.flush(), this.interval)
    }
  }

  /**
   * 停止定时器
   */
  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  }

  /**
   * 添加事件到队列
   * @param {string} daId - 事件ID
   * @param {Object} extraData - 事件数据
   */
  addEvent({ daId, extraData }) {
    if (typeof window === 'undefined' || !daId) return

    if (!this.eventQueue.has(daId)) {
      this.eventQueue.set(daId, [])
    }

    const queue = this.eventQueue.get(daId)
    queue.push(extraData)

    this.startTimer()
  }

  /**
   * 发送指定daId的事件队列
   * @param {string} daId - 事件ID
   */
  flushById(daId) {
    const events = this.eventQueue.get(daId) || []
    if (events.length === 0) return

    const mergedData = this.mergeExtraData(events)
    this.send({ daId, mergedData })

    this.eventQueue.set(daId, [])
  }

  /**
   * 发送所有队列中的事件
   */
  flush() {
    for (const daId of this.eventQueue.keys()) {
      this.flushById(daId)
    }

    if (this.eventQueue.size === 0) {
      this.stopTimer()
    }
  }

  /**
   * 发送事件(可被子类重写以自定义发送逻辑)
   * @param {string} daId - 事件ID
   * @param {Object} mergedData - 合并后的数据
   */
  send({ daId, mergedData }) {
    try {
      daEventCenter.triggerNotice({ daId, extraData: mergedData })
      this.debugLog(`Event sent - daId: ${daId}, data:`, mergedData)
    } catch (error) {
      this.debugLog('Error in send:', error)
    }
  }

  /**
   * 合并事件数据(抽象方法)
   * @param {Array} events - 待合并的事件数组
   * @returns {Object} 合并后的数据
   */
  // eslint-disable-next-line no-unused-vars
  mergeExtraData(events) {
    throw new Error('Method mergeExtraData must be implemented')
  }

  /**
   * 销毁实例
   */
  destroy() {
    this.stopTimer()
    this.eventQueue.clear()
  }

  /**
   *  输出日志
   * @param {...any} args
   */
  debugLog(...args) {
    if (this.debug) {
      // eslint-disable-next-line no-console
      console.log(...args)
    }
  }
}

/**
 * expose_scenesabt 事件聚合发送实现类
 */
class ExposeScenesAbtAggregator extends EventAggregator {
  constructor() {
    super()
  }

  mergeExtraData(events) {
    return { scenes_list: events }
  }
}

export const exposeScenesAbtAggregator = new ExposeScenesAbtAggregator()
