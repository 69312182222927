export function getUnPaidImgs(goodsList = [], limit = 3) {
  let imgs = []
  for(const goods of goodsList) {
    if(goods?.is_prime_goods || goods?.is_xtra_goods) continue
    imgs.push({
      goods_img: goods?.blind_box_flag == 1 ? 'http://img.ltwebstatic.com/images3_acp/2024/11/22/24/1732274754479c270973cb429359284ea52acad318.png' : goods?.product?.goods_img,
      goods_name: goods?.product?.goods_name
    })
    if(imgs.length >= limit) break
  }
  return imgs
}
