<template>
  <div class="cart-item__bottom-block">
    <div
      v-if="promotionCutPriceShow && soldoutIds.indexOf(item.id) == -1"
      class="promotion-price-cut"
    >
      <CartReducePriceIcon
        class="reduce-price-icon"
        :is-rw="locals.IS_RW"
      />
      <span v-html="reducePriceTip"></span>
    </div>
    <div class="cart-item__footer">
      <CartItemPriceSpan
        v-if="!item.isBlindBoxFlag"
        :item="item"
        :show-before-coupon-price="showBeforeCouponPrice"
        :show-estimated-tag="showEstimatedTag"
        :show-discount-on-estimated="showDiscountOnEstimated"
        :show-discount-tag="showDiscountTag"
        :discount-text="discountText"
        :is-batch-active-page="isBatchActivePage"
        from-scene="cartLine"
      />
      <div class="cart-item__footer-button">
        <!-- add save del -->
        <NewCartItemQty
          v-if="isShowNewQty"
          v-bind="$attrs"
          :language="language"
          :item="item"
          :disabled="qtyDisabled"
        />
        <div
          v-if="!isBatchActivePage"
          class="gd-operate"
        >
          <!-- 找相似 -->
          <span
            v-if="onSaleSimilarShow"
            v-expose="{
              id: '1-7-3-27',
              data: { 
                sale_status: 0,
                main_goods_id: item.product.goods_id,
              }
            }"
            v-tap="{
              id: '1-7-3-28',
              data: { 
                sale_status: 0,
                main_goods_id: item.product.goods_id,
              }
            }"
            class="cart-item__deleteHandler-text"
            @click="handleShowSimilarModal(item)"
          >
            <sui_icon_search_18px_1 size="16px" />
          </span>
          <!-- 收藏按钮 -->
          <s-popover
            v-if="isShowWishAdd"
            ref="M2WPopover"
            v-model="showM2W"
            class="j-cart-item-M2W"
            :trigger="item.isNewcomerItem ? 'hover' : 'click'"
            :placemen="item.isNewcomerItem ? 'top-end' : 'bottom'"
            :title="item.isNewcomerItem ? '' : language.SHEIN_KEY_PC_17955"
            :content="item.isNewcomerItem ? language.SHEIN_KEY_PC_27119 : ''"
            :delay-render="300"
            :show-footer="!item.isNewcomerItem"
            @opened="onOpened('save')"
            @button-click="buttonM2W"
          >
            <template #buttonYes>
              {{ language.SHEIN_KEY_PC_15737 }}
            </template>
            <template #buttonNo>
              {{ language.SHEIN_KEY_PC_15738 }}
            </template>
            <template #reference>
              <span
                v-if="!item.isGiftItem"
                class="cart-item__deleteHandler-text"
                :tabindex="isBatchActivePage ? -1 : 0"
                role="button"
                @click="ClickAddToWishlist"
              >
                <sui_icon_save_16px_1 size="16px" />
              </span>
            </template>
          </s-popover>
          <!-- 删除 -->
          <s-popover
            v-model="showDelete"
            placemen="bottom"
            :title="language.SHEIN_KEY_PC_15745"
            :show-footer="true"
            trigger="unset"
            @button-click="buttonDel"
          >
            <template #buttonYes>
              {{ language.SHEIN_KEY_PC_15737 }}
            </template>
            <template #buttonNo>
              {{ language.SHEIN_KEY_PC_15738 }}
            </template>
            <template #reference>
              <span
                class="cart-item__deleteHandler-text cart-item__deleteHandler-text--del"
                :tabindex="isBatchActivePage ? -1 : 0"
                role="button"
                @click="onCLickDeleteBtn"
              >
                <sui_icon_delete_16px_1 size="16px" />
              </span>
            </template>
          </s-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cartLoginView from '../../../utils/cartLoginView'
import NewCartItemQty from './NewCartItemQty.vue'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { SMessage } from '@shein-aidc/sui-message'
import { daEventCenter } from 'public/src/services/eventCenter'
import { batchWishGoods, deleteCartItem } from '../../../utils/fetcher'
import { htmlDecode as _htmlDecode, template, transformImg } from '@shein/common-function'
import { LabelKeyMap } from '@/public/src/pages/cart_new/utils/cartFilterLabels.js'
import CartReducePriceIcon from './CartReducePriceIcon.vue'
import CartItemPriceSpan from './CartItemPriceSpan.vue'
import SimilarPopover from '../../../../common/similar_products'
import { sui_icon_search_18px_1, sui_icon_save_16px_1, sui_icon_delete_16px_1 } from '@shein-aidc/icon-vue3'
import useDeleteTips from '../../deleteTips/hooks/useDeleteTips'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'

daEventCenter.addSubscriber({ modulecode: '1-7-3' })

export default {
  name: 'CartItemPriceBlock',
  setup() {
    const {
      showDeleteTips,
      getExtraData,
    } = useDeleteTips()
    return {
      showDeleteTips,
      getExtraData,
    }
  },
  components: {
    CartReducePriceIcon,
    CartItemPriceSpan,
    sui_icon_search_18px_1,
    sui_icon_save_16px_1,
    sui_icon_delete_16px_1,
    NewCartItemQty,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    index: {
      type: Number,
      default: 0
    },
    isBatchActivePage: {
      type: Boolean,
      default: false,
    },
    showBeforeCouponPrice: {
      type: Boolean,
      default: false,
    },
    showEstimatedTag: {
      type: Boolean,
      default: false,
    },
    showDiscountOnEstimated: {
      type: Boolean,
      default: false,
    },
    showDiscountTag: {
      type: Boolean,
      default: false,
    },
    discountText: {
      type: String,
      default: '',
    },
  },
  data(){
    return {
      showM2W: false,
      showDelete: false,
    }
  },
  computed: {
    ...mapState(['locals', 'language', 'integrityPromotionInfo', 'soldoutIds', 'ABTParams', 'filterType']),
    ...mapGetters(['showQuickShipTag', 'cartMallSwitchGoods', 'cartSaleAttrSwitchGoods']),
    promotionCutPriceShow () {
      // TR-39482新增逻辑：降价筛选标签下商品行无论是否勾选，都展示降价信息
      const hasReduce = this.item?.reduce_price?.reduceAmount?.amount > 0
      if (this.filterType === LabelKeyMap.MARKDOWNS) {
        return hasReduce
      } else {
        return hasReduce && this.item?.is_checked != 1
      }
    },
    countDownData(){
      if(this.item.promotionTypeIds?.includes(3) || this.item.promotionTypeIds?.includes(8) || (this.item.promotionTypeIds?.includes(10) && [1, 4, 5].includes(Number(this.item.flashType)))){
        let promotion = this.item.product.product_promotion_info.find(item => [3, 8, 10].includes(+item.type_id))
        if(promotion) {
          let targetPromotion = this.integrityPromotionInfo[promotion.promotion_id]
          if(targetPromotion?.is_count_down){
            return targetPromotion.end_time
          }
        }
      }
      if(this.item.product.insurable == 1 && this.item.product.insurable_time) {
        let end = this.item.product.insurable_time || 0
        if(end){
          end = (end + '').length >= 13 ? end : end * 1000
        }
        const now = (new Date()).getTime()
        const diffTime = end - now
        const diffDays = Math.floor(diffTime / 1000 / 60 / 60 / 24)
        return  diffDays < 3 ? this.item.product.insurable_time : ''
      }
      return ''
    },
    isPromotionTypeId(){
      return this.item.promotionTypeIds?.includes(3) || this.item.promotionTypeIds?.includes(8) || (this.item.promotionTypeIds?.includes(10) && [1, 4, 5].includes(Number(this.item.flashType))) || this.item.promotionTypeIds?.includes(29)
    },
    showQuickShip(){
      return this.showQuickShipTag && this.soldoutIds.indexOf(this.item.id) == -1 && this.item.quick_ship == 1 ? true : false
    },
    // 附赠品不支持编辑
    isAddBuyGoodsOrGiftGoods () {
      return this.item.product.product_promotion_info && this.item.product.product_promotion_info.some(promo => [1, 2].includes(+promo.promotion_product_type)) ? true : false
    },
    reducePriceTip() {
      return this.template(`<b>${this.item.reduce_price?.reduceAmount?.amountWithSymbol || '$2.00'}</b>`, this.language.SHEIN_KEY_PC_16553)
    },
    onSaleSimilarShow() {
      return this.ABTParams?.SheinFindSimilar?.param?.OnsaleSimilarShow == 'Show' && !this.item.isNewcomerItem && !this.isSwitchItem
    },
    isShowWishAdd() {
      return (!this.isAddBuyGoodsOrGiftGoods || this.soldoutIds.indexOf(this.item.id) > -1) && !this.isSwitchItem
    },
    isSwitchItem() {
      return (this.cartMallSwitchGoods && this.item.isMallSwitchGoods) || (this.cartSaleAttrSwitchGoods && this.item.isSaleAttrSwitchGoods)
    },
    /**
     * 是否显示
     * 1. 不是附赠品会显示
     * 2. 不是失效商品会显示
     * 3. 因为勾选数量超过库存导致失效的商品会显示
     */
    isShowNewQty() {
      return !this.isSwitchItem && (!this.item.isGiftItem && this.soldoutIds.indexOf(this.item.id) == -1 || (this.item.status == 1 && this.item.realTimeInventory && this.item.quantity > this.item.realTimeInventory))
    },
    /**
     * 是否置灰
     * 置灰的情况：1.批量编辑  2.新人专享价商品  3.附赠品
     */
    qtyDisabled() {
      return this.isBatchActivePage || this.item.isNewcomerItem || this.isAddBuyGoodsOrGiftGoods
    },
  },
  methods: {
    ...mapMutations(['updateField']),
    ...mapActions(['asyncUpdateCartInfo', 'fetchModifyCartCheckStatus', 'handleFlashSaleOverScene']),
    onOpened(type) {
      const deletsTipsExtraData = this.getExtraData(this.item, 0)
      switch (type) {
        case 'save':
          if (this.item.isNewcomerItem) {
            daEventCenter.triggerNotice({ daId: '1-7-3-32' })
          } else {
            daEventCenter.triggerNotice({ daId: '1-7-3-36' })
          }
          break
        case 'delete':
          this.showDelete = true
          daEventCenter.triggerNotice({ daId: '1-7-3-37', extraData: deletsTipsExtraData })
          break
        default:
          break
      }
    },
    buttonDel(type){
      if(type == 'yes'){
        this.confirmDel()
      } else {
        this.cancelDel()
      }
    },
    confirmDel (type) {
      const deletsTipsExtraData = this.getExtraData(this.item, 0)
      deleteCartItem({ id: this.item.id })
        .then(res => {
          this.showDelete = false
          if (res.code == 0) {
            this.$emit('delOrSaveOpt', 2, async ()=>{
              await this.asyncUpdateCartInfo({ cartInfo: res.info })
              this.reflashGetCoupon()
            })
            daEventCenter.triggerNotice({ daId: '1-7-3-38', extraData: { result: 1, ...deletsTipsExtraData } })
            if (type === 'showSimilar') {
              this.handleShowSimilarModal(this.item, { recAnalysis: { activeFrom: 'deleteproducts' }, isForceUpdate: true, extendTitle: this.language.SHEIN_KEY_PC_16963, desc: this.language.SHEIN_KEY_PC_27978, titleConfig: { enValue: 'deleteproducts' } })
            }
          } else {
            daEventCenter.triggerNotice({ daId: '1-7-3-38', extraData: { result: 0, ...deletsTipsExtraData } })
          }
          if (typeof GB_analysis_obj !== 'undefined') GB_analysis_obj.deleteItem({ item: this.item, value: res.code == '0' ? 1 : 0 })
        })
    },
    cancelDel() {
      const deletsTipsExtraData = this.getExtraData(this.item, 0)
      this.showDelete = false
      daEventCenter.triggerNotice({ daId: '1-7-3-38', extraData: { result: 0, ...deletsTipsExtraData } })
    },
    // 领券接口更新
    reflashGetCoupon () {
      this.updateField({
        key: 'isReflashGetCouponFetch',
        value: true
      })
    },
    buttonM2W(type){
      if(type == 'yes'){
        this.handleM2W()
      } else {
        this.cancelM2W()
      }
    },
    cancelM2W () {
      daEventCenter.triggerNotice({
        daId: '1-7-1-2',
        extraData: {
          code: 'cart',
          result: 0,
          mall_code: this.item.mall_code,
          postData: {
            action: 0, // 选中发1, 取消发0
            goods_id: this.item.product.goods_id,
            sku: this.item.product.goods_sn,
            detail: this.item.product,
            index: this.index,
            quickShip: this.showQuickShip ? 1 : 0
          },
          from: 'cart',
          eventCategory: '购物车页'
        }
      })
      this.showM2W = false
    },
    handleM2W () {
      const { item, index } = this
      this.showM2W = false
      let handle = ()=>{
        this.$emit('delOrSaveOpt', 1)
        let _this = this
        return ()=>{
          _this.$emit('delOrSaveOpt', 3)
        }
      }
      const isLogin = !!isLoginFn()
      if (isLogin) {
        this.save(item, index, handle)
      } else {
        cartLoginView.login({
          type: 'saveforlater',
          beforeCartSumQuantity: this.cartSumQuantity,
          cb: this.loginSuc
        })
      }
    },
    save(item, index, cb) {
      if (this.fetching) return false
      this.fetching = true // 下一行的$emit改状态有延时, 故在这里提前
      let reqBatchIds = []
      reqBatchIds.push(item.id)
      batchWishGoods({ ids: reqBatchIds }).then(async res=>{
        this.fetching = false
        // 【埋点统一】 收藏
        daEventCenter.triggerNotice({
          daId: '1-7-1-2',
          extraData: {
            code: 'cartBuyGifts',
            mall_code: item.mall_code,
            result: {
              data: res
            },
            postData: {
              action: 1, // 选中发1, 取消发0
              goods_id: item.product.goods_id,
              sku: item.product.goods_sn,
              detail: item.product,
              index: index,
              quickShip: this.showQuickShip ? 1 : 0
            },
            from: 'cart',
            eventCategory: '购物车页'
          }
        })

        if (res.code == 0) {

          if(cb && typeof cb == 'function'){
            let callback = cb()
            setTimeout(async ()=>{
              await callback()
              await this.asyncUpdateCartInfo({ cartInfo: res.info })
              this.reflashGetCoupon()
            }, 1500)
          } else {
            await this.asyncUpdateCartInfo({ cartInfo: res.info })
            this.reflashGetCoupon()
          }
          if (typeof GB_analysis_obj !== 'undefined') {
            GB_analysis_obj.saveForLater({
              sku: item.product.goods_sn,
              value: 1,
              item: item
            })
          }
        } else {
          if (res.code == -4 || res.code == -2) { // 触发容灾
            SMessage({
              offset: 136,
              type: 'error',
              message: this.htmlDecode(this.language.SHEIN_KEY_PC_17753),
            })
          } else if (Number(res.code) === 400427 && res.info){ // 24小时内收藏数超过限制
            SMessage({
              offset: 136,
              type: 'info',
              message: this.template(res.info.limit, this.language.SHEIN_KEY_PC_19347),
            })
          } else {
            SMessage({
              offset: 136,
              type: 'error',
              message: this.htmlDecode(this.language.SHEIN_KEY_PC_17737),
            })
          }

        }
      })
    },
    loginSuc: async function (result, res){
      if(res.code == 0) {
        await this.asyncUpdateCartInfo({ cartInfo: res.info })
        this.reflashGetCoupon()
        if(!result.equalCart){
          SMessage({
            offset: 136,
            type: 'info',
            message: this.htmlDecode(this.language.SHEIN_KEY_PC_17957),
            duration: 5000
          })
        }
      }

    },
    ClickAddToWishlist () {
      if (this.item.isNewcomerItem) return
      daEventCenter.triggerNotice({ daId: '1-7-3-1' })
    },
    handleShowSimilarModal(item, config) {
      if (this.isBatchActivePage) return
      SimilarPopover.showModal(item.product, config)
    },
    transformImg: transformImg,
    template: template,
    htmlDecode: function (text){
      return _htmlDecode({ text: text })
    },
    onCLickDeleteBtn() {
      const item = this.item
      if (!this.soldoutIds.includes(item.id)) {
        // 展示新版删除弹窗
        const showDeleteTipsResult = this.showDeleteTips({
          cartAbtInfo: this.ABTParams,
          item,
          language: this.language,
          isBatch: false,
          onUncheck: () => {
            this.$emit('on-uncheck', { label: item.id, checked: false })
          },
          onDelete: () => {
            this.confirmDel()
          },
          onMoveToWishList: () => {
            this.handleM2W()
          },
          onDeleteAndFindSimilar: () => {
            this.confirmDel('showSimilar')
          },
        })
        if (showDeleteTipsResult) return
      }
      this.onOpened('delete')
    },
  },
  emits: ['delOrSaveOpt', 'on-uncheck'],
}
</script>

<style lang="less" scoped>
.promotion-price-cut{
  display: flex;
  padding-top: 4px;
  line-height: 14px;
  font-size: 13px;
  color: @sui_color_highlight;
  font-weight: normal;
  .reduce-price-icon {
    margin-right: 2px;
  }
}
.cart-item__footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  .cart-item__footer-button {
    display: flex;
    align-items: center;
    margin-left: auto;
    &>:not(:first-child){
      margin-left: 20px;
    }
  }
}
.gd-operate {
  display: flex;
  align-items: center;
  &>:not(:last-child){
    margin-right: 12px;
  }
}
.cart-item__deleteHandler-text {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  color: #222;
  text-decoration: none;
  font-size: 13px;
  &:hover {
    background: #f6f6f6;
  }
  /* stylelint-disable-next-line */
  &--del {
    position: relative;
    z-index: 1;
  }
}
</style>
